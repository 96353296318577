<template>
  <div class="assets-check list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="8">
          <el-form-item label="盘点时间" prop="checkTime">
            <el-date-picker v-model="formData.checkTime" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择盘点时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="16" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column prop="checkTime" label="盘点时间"></el-table-column>
        <el-table-column prop="successCount" label="成功数量"></el-table-column>
        <el-table-column prop="failureCount" label="失败数量">
          <template #default="{row}">
            <span :class="{'warning': row.failureCount > 0}">{{row.failureCount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceName" label="操作人"></el-table-column>
        <el-table-column label="操作" width="120" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleDetail(row)">详情</el-button>
            <el-button @click="handleExport(row)">导出</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情弹框 -->
    <el-dialog v-model="detailDialogVisible" width="1000px" @close="detailDialogVisible = false">
      <template #title>
        <h1>详细信息</h1>
        <p>details</p>
      </template>
      <div class="detail-content">
        <div class="btn-box">
          <el-button :class="{'active': checkType == 'LOSS'}" @click="changeCheckType('LOSS')">盘点异常</el-button>
          <el-button :class="{'active': checkType == 'PROFIT'}" @click="changeCheckType('PROFIT')">盘点正常</el-button>
        </div>
        <el-table :data="detailTableData">
          <el-table-column prop="code" label="资产编号"></el-table-column>
          <el-table-column prop="name" label="资产名称"></el-table-column>
          <el-table-column prop="typeName" label="资产类型" width="100"></el-table-column>
          <el-table-column prop="workStationName" label="工段" width="80"></el-table-column>
          <el-table-column prop="empStationName" label="工位" width="80"></el-table-column>
          <el-table-column label="资产状态" width="80">
            <template #default="{row, column}">
              <span :class="{'warning': row.status != 'OK'}">{{formatterStatus(row, column, row.status)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="点检状态" width="80">
            <template #default="{row, column}">
              <span :class="{'warning': row.tpmStatus != 'OK'}">{{formatterTpmStatus(row, column, row.tpmStatus)}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            @current-change="handleDetailCurrentPage"
            :current-page="detailPagination.currentPage"
            :page-size="detailPagination.pageSize"
            layout="prev, pager, next"
            :total="detailPagination.total">
          </el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button @click="detailDialogVisible = false" class="active">关 闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { assets, common } from '@/api'

export default {
  name: 'AssetsList',
  data() {
    return {
      formData: { // 查询表单
        checkTime: '',
      },
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },

      statusList: [], // 资产状态列表
      tpmStatusList: [], // 点检状态列表
      detailDialogVisible: false, // 详情弹框visible
      curDetailRow: null, // 当前操作行
      detailTableData: [], // 详情列表
      detailPagination: { // 详情分页信息
        currentPage: 1,
        pageSize: 5,
        total: 0
      },
      checkType: 'LOSS', // 盘点正常/异常
    }
  },
  created() {
    this.loadTableData()
  },
  methods: {
    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await assets.getAssetsCheck(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 点击“详情”
    handleDetail(row) {
      this.getDict()

      this.curDetailRow = {...row}
      this.detailPagination.currentPage = 1
      this.loadDetailTableData()

      this.detailDialogVisible = true
    },
    // 获取详情列表数据
    async loadDetailTableData() {
      let params = {
        recordId: this.curDetailRow.id,
        type: this.checkType,
        pageNum: this.detailPagination.currentPage,
        pageSize: this.detailPagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await assets.getAssetsCheckDetail(params)
      if (code === 0) {
        this.detailTableData = data
        this.detailPagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变详情列表当前页
    handleDetailCurrentPage(currentPageNo) {
      this.detailPagination.currentPage = currentPageNo
      this.loadDetailTableData()
    },
    // 改变详情列表-盘点正常/异常
    changeCheckType(type) {
      this.checkType = type
      this.detailPagination.currentPage = 1
      this.loadDetailTableData()
    },
    // 获取字典数据
    async getDict() {
      let types = 'ASSET_STATUS,TPM_STATUS'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.statusList = data.ASSET_STATUS || []
        this.tpmStatusList = data.TPM_STATUS || []
      } else {
        this.$message.error(msg)
      }
    },
    // 格式化资产状态
    formatterStatus(row, column, cellValue) {
      let label = cellValue
      for(let item of this.statusList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
    // 格式化点检状态
    formatterTpmStatus(row, column, cellValue) {
      let label = cellValue
      for(let item of this.tpmStatusList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
    // 导出
    async handleExport({id}) {
      await common.downloadFile('/asset/check/export', null , {id})
    }
  },
}
</script>

<style lang="scss" scoped>
.assets-check {
  .list-box {
    .el-table {
      .warning {
        color: #DF3944;
      }
    }
  }

  .detail-content {
    .btn-box {
      margin-bottom: 20px;
      .el-button {
        width: 170px;
        & + .el-button {
          margin-left: 30px;
        }
      }
    }
    .el-table {
      .warning:not(th) {
        color: #DA0306;
      }
    }
  }
}
</style>